<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">月卡管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editMonthCard()">添加月卡</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="batchEditMonthCard()">批量添加</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="名称" v-model="pageParam.params.name" />
                <le-select-remote-search label="所属项目" v-model="pageParam.params.incomeDistriId" :options="optionsincomeDistri" placeholder="请选择项目(可搜索)" />
                <le-input label="站点名称" v-model="pageParam.params.stationName" />
                <le-input label="月卡ID" v-model="pageParam.params.id" />
            </le-search-form>
            <le-pagview ref="monthcardlistPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.monthCardlist">
                <el-table 
                    ref="monthCardlist" 
                    :data="tableData" 
                    :highlight-current-row="true"  
                    style="width: 100%" 
                    @filter-change='filterFun' 
                    :border="true"
                    :span-method='mergeCols'
                    v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column type="expand" fixed="left" prop='expand'>
                        <template slot-scope="props">
                            <el-form label-position="left" ref="moreInfo" :style="'margin-left:'+moreInfoLeft+';z-index:999'">
                                <!-- <el-form-item label="是否临时卡：" >
                                    <span>{{ props.row.isTempCard | initDic(isTempcardDic) }}</span>
                                </el-form-item> -->
                                <el-form-item label="是否系统默认卡：">
                                    <span>{{ props.row.isSystemDefaultCard | initDic(isSystemDefaultDic) }}</span>
                                </el-form-item>
                                <el-form-item label="促销描述：">
                                    <span>{{ props.row.promotionDescription }}</span>
                                </el-form-item>
                                <el-form-item label="激活模式：">
                                    <span>{{ props.row.activationMode | initDic(activeCardDic) }}</span>
                                </el-form-item>
                                <!-- <el-form-item label="是否需要邮寄：">
                                    <span>{{ props.row.cardNeedPost | initDic(needPostDic) }}</span>
                                </el-form-item> -->
                                <el-form-item label="实体卡费用：">
                                    <span>￥{{ util.numFormat(props.row.cardPrice) }}</span>
                                </el-form-item>
                                <el-form-item label="创建时间：">
                                    <span>{{ props.row.createTimeText }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="80" fixed="left"></el-table-column>
                    <el-table-column prop="name" label="名称" min-width="140" fixed="left">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="editMonthCard(row)">{{ row.name?row.name:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeDistriName" label="所属项目" min-width="200"></el-table-column>
                    <el-table-column prop="stationName" label="站点" min-width="240">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc a-flex-wrap">
                                <span class="stationTag" v-for="(item,index) in row.stationList" :key="index">{{ item.name?item.name:'-' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="showMode" label="可见模式" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.showMode | initDic(showModelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargingExpireType" label="资源类型" min-width="130">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargingExpireType | initDic(chargingExpireTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="applicableDescription" label="适用范围描述" min-width="200"></el-table-column>
                    <el-table-column 
                        prop="isTempCard" 
                        label="卡种类" 
                        min-width="120" 
                        column-key="isTempCard"
                        :filter-multiple='false'
                        :filters="isTempcardDic">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>{{ pageParam.params.isTempCard | initTablelabel(isTempcardDic,'卡种类') }}</span>
                                <img style="width:15px;height:15px;margin-left:7px" src="../../assets/icon/filter-icon.png" alt="">
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.isTempCard | initDic(isTempcardDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="type" 
                        label="卡类型" 
                        min-width="120" 
                        column-key="cardType"
                        :filter-multiple='false'
                        :filters="cardTypeDic">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>{{ pageParam.params.type | initTablelabel(cardTypeDic,'卡类型') }}</span>
                                <img style="width:15px;height:15px;margin-left:7px" src="../../assets/icon/filter-icon.png" alt="">
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.type | initDic(cardTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="activityPrice" label="促销价/划线价" min-width="150">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.activityPrice) }}/￥{{ util.numFormat(row.price) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="validity" label="有效时长" min-width="100">
                        <template slot-scope="{ row }">
                            <span v-if="row.type == 5">{{  row.validity == -1 ? '长期' :  row.validity + '天' }}</span>
                            <span v-else>{{  row.validity  }}{{ row.isTempCard==1?'小时':'天' }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="cardGroupName" label="卡组" min-width="140"></el-table-column> -->
                    <el-table-column 
                        prop="payWay" 
                        label="支付方式" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payWay | initDic(payWayDic) }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="name" label="充电次数(次)" min-width="100">
                        <template slot-scope="{ row }">
                            <span v-if="row.quotaTimes==-1">不限制</span>
                            <span v-else>{{ row.quotaTimes?row.quotaTimes:'' }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column 
                        prop="quotaDuration" 
                        min-width="120"
                        label="充电时长限制">
                        <template slot-scope="{ row }">
                            <span v-if="row.quotaDuration!=-1">{{ row.quotaDuration?row.quotaDuration+'分钟/日':'-' }}</span>
                            <span v-else>不限制</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="parkTimes" label="开门次数(次)" min-width="100">
                        <template slot-scope="{ row }">
                            <span v-if="row.parkTimes==-1">不限制</span>
                            <span v-else>{{ row.parkTimes?row.parkTimes+'次/日':'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enableTimeText" label="开售时间" min-width="170"></el-table-column>
                    <el-table-column prop="expireTimeText" label="失效时间" min-width="170"></el-table-column>
                    <el-table-column prop="operate" label="操作" width="50" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="添加用户套餐" placement="top">
                                <img src="../../assets/icon/option-add-circle.png" class="a-wh-16" @click="addUserMonth(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="showMoreInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editMonthCard(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="item in optionsMoreOperation" 
                                            :command='item.value+"#"+scope.$index' 
                                            :disabled="item.value == 6 && scope.row.type == 4"
                                            :key="item.value" 
                                            :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
        <bind-real-card ref="bindCard"></bind-real-card>
    </div>
</template>

<script>
import BindRealCard from './child/bindRealCard.vue';
import util from '../../../src/utils/util'
    export default {
        components:{
            BindRealCard
        },
        data () {
            return {
                util: util,
                tableData: [],
                cosTableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getMonthcardPage,
                    method: "post",
                    params: {
                        name: '',
                        type: '',
                        stationName: '',
                        id: '',//月卡id
                        incomeDistriId: '',
                        isTempCard: ''
                    },
                    freshCtrl: 1,
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                fileUrl: '',
                cardTypeDic: [],//卡类型字典
                isTempcardDic: [],//是否临时卡字典
                isSystemDefaultDic: [],//是否系统默认卡字典
                activeCardDic: [],//激活模式字典
                needPostDic: [],//是否邮寄
                showModelDic: [],//可见模式字典
                payWayDic: [],// 支付类型字典
                chargingExpireTypeDic: [],
                optionsMoreOperation: [{
                    label: "删除",
                    value: 1,
                }, {
                    label: "置顶",
                    value: 2,
                }, {
                    label: "上移",
                    value: 3,
                }, {
                    label: "下移",
                    value: 4,
                }],
                moreInfoLeft: '16px'
            }
        },
        mounted () {
            this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
            this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
            this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
            this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
            this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
            this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
            this.$getDic('timeType').then(res=>{ this.timeTypeDic = res; })
            this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
            this.$getDic('chargingExpireType').then(res=>{ this.chargingExpireTypeDic = res; })
            this.$nextTick(()=>{
                let tableDom = this.$refs.monthCardlist.bodyWrapper
                tableDom.addEventListener('scroll', this.tableScroll, true);
            })   
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            tableScroll (e) {
                // let left = this.$refs.monthCardlist.bodyWrapper.scrollLeft 
                // this.moreInfoLeft = left + 16 + 'px'
            },
            //获取列表
            setTableData(data,fileUrl) {
                let arr = []
                data.map(item=>{
                    item.voList.map(ite=>{
                        arr.push({
                            groupId: item.groupId,
                            ...ite
                        })
                    })
                });
                this.tableData = arr
                this.getCosTableData()
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    type: '',
                    id: '',//月卡id
                    stationName: '',
                    incomeDistriId: '',
                    isTempCard: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['monthcardlistPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 添加/编辑月卡
            editMonthCard (datas) {
                this.$router.push({
                    path:'/monthCard/monthCard-edit',
                    query: {
                        id: datas?datas.id:'',
                        groupId: datas?datas.groupId:''
                    }
                })
            },
            batchEditMonthCard () {
                this.$router.push({
                    path:'/monthCard/monthCard-batch-edit',
                })
            },
            handlerOperation (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                switch (command) {
                    case '1':
                        this.deleteMonthCard(this.tableData[index])
                        break;
                    case '2':
                        this.sortMonthCard(this.tableData[index],3)
                        break;
                    case '3':
                        this.sortMonthCard(this.tableData[index],1)
                        break;
                    case '4':
                        this.sortMonthCard(this.tableData[index],2)
                        break;
                    case '5':
                        this.suspendMonthCard(this.tableData[index])
                        break;
                    case '6':
                        this.addUserMonth(this.tableData[index])
                        break;
                    default:
                        break;
                }
            },
            // 删除套餐组
            deleteMonthCard (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.deleteMonthcard,
                        method: "post",
                        params: {
                            // monthcardId: datas.id,
                            groupId: datas.groupId,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                        if(err = 'Error: 此卡片已经管理用户请先解绑用户'){
                            this.$confirm('此卡片已经管理用户请先解绑用户','温馨提示',{
                                confirmButtonText:'去解绑'
                            })
                            .then(_=>{
                                this.$router.push({
                                    path: '/monthCard/monthCard-relation'
                                })
                            })
                        }
                        
                    })
                })
                .catch(_=>{})
            },
            // 卡片排序
            sortMonthCard (datas,type) {
                let content = type == 3 ? '是否确认置顶？' : type == 1 ? '是否确认上移？' : '是否确认下移？'
                this.$confirm(content,'温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.monthcardMove,
                        method: "post",
                        params: {
                            groupId: datas.groupId,
                            moveOperation: type
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            // 暂停卡片
            suspendMonthCard (datas) {
                this.$confirm('是否确认暂停？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.stopMonthcard,
                        method: "post",
                        params: {
                            monthcardId: datas.id,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            addUserMonth (datas) {
                this.$refs['bindCard'].dialogVisible = true
                this.$refs['bindCard'].form.monthcardId = datas.id 
                if(datas.type == 4){
                    this.$refs['bindCard'].isInputCardNo = false
                }else{
                    this.$refs['bindCard'].isInputCardNo = true
                }
                this.$refs['bindCard'].monthCardInfo = datas
            },
            showMoreInfo (row) {
                this.$refs['monthCardlist'].toggleRowExpansion(row)
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='cardType'){
                        if(value[key].length==0){
                            this.pageParam.params.type = ''
                        }else{
                            this.pageParam.params.type = value[key][0]
                        }
                    }
                    if(key=='isTempCard'){
                        if(value[key].length==0){
                            this.pageParam.params.isTempCard = ''
                        }else{
                            this.pageParam.params.isTempCard = value[key][0]
                        }
                    }
                }
                this.handlerSearch()
            },
            exportfile () {
                if(!this.fileUrl) {
                    this.$message.warning('暂无可导出文件')
                    return
                }
                this.$confirm('是否确认导出文件？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Utils.downloadUrlFile(this.fileUrl, '省份经营数据列表')
                }).catch(_=>{ })
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                if(columnIndex !== 1 && columnIndex !== 5 && columnIndex !== 8 && columnIndex !== 10 && columnIndex !== 11 && columnIndex !== 17){
                    for (let index = 0; index < this.cosTableData.length; index++) {
                        const element = this.cosTableData[index];
                        for (let c_index = 0; c_index < element.length; c_index++) {
                            if(rowIndex == element[c_index]){
                                if(c_index == 0){
                                    return {
                                        rowspan: element.length,
                                        colspan: 1
                                    }
                                }else{
                                    return {
                                        rowspan: 0,
                                        colspan: 0
                                    }
                                }   
                            }
                        }
                    }
                }
            },
            // 获取需要合并单元格的数据
            getCosTableData () {
                this.cosTableData = []
                let obj = {}
                // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
                this.tableData.map((item,index)=>{
                    if(obj[item.groupId]){
                        obj[item.groupId].push(index)
                    }else{
                        obj[item.groupId] = []
                        obj[item.groupId].push(index)
                    }
                })
                // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
                for (const key in obj) {
                    if(obj[key].length > 1)
                    this.cosTableData.push(obj[key])
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.monthCard{
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__expand-column .cell {
        // display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>