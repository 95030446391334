<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="500px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone" maxlength="11" placeholder="请输入" @blur="getTargetUserInfo"></el-input>
                </el-form-item>
                <el-form-item label="卡片类型" prop="" v-if="isInputCardNo">
                    <el-radio-group v-model="cardType">
                        <el-radio :label="2">电子卡</el-radio>
                        <el-radio :label="1">实体卡</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="实体卡号" prop="cardNo" v-if="isInputCardNo && cardType == 1">
                    <el-input v-model="form.cardNo" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="RFID" prop="bindRfCardNo">
                    <el-input v-model="form.bindRfCardNo" placeholder="请输入RFID编号"></el-input>
                </el-form-item>
                <el-form-item label="购买站点" prop="stationId">
                    <el-select v-model="form.stationId">
                        <el-option v-for="item in monthCardInfo.stationList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否工本费" prop="needCard">
                    <el-switch
                        v-model="form.needCard"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                    <span style="padding-left: 20px" v-show="form.needCard">￥{{ util.numFormat(monthCardInfo.cardPrice) }}</span>
                </el-form-item>
                <el-form-item label="车辆" prop="carInfo" v-show="userInfo">
                    <el-button type="primary"  @click="addCar" v-if="!carInfo">添加车辆</el-button>
                    <div class="a-flex-rfsc" style="align-items: stretch" v-else>
                        <el-image
                            style="width: 120px; height: 120px; background: #f5f5f5; border-radius: 6px;"
                            :src="carInfo.imagePath"
                            :preview-src-list="[carInfo.imagePath]"
                            fit="contain"></el-image>
                        <div class="a-flex-csbfs a-ml-10 a-fs-12">
                            <span>品牌型号：{{ carInfo.brand }}</span>
                            <span>车牌号：{{ carInfo.carNumber }}</span>
                            <span>车辆类型：{{ carInfo.type.name }}</span>
                            <span>电瓶规格：{{ carInfo.batteryVoltage.batteryVoltage }}</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="人脸" prop="face" v-show="userInfo">
                    <le-upload-file-img v-if="!faceInfo" ref="icon" label="" :limit="1" v-model="faceUrl"></le-upload-file-img>
                    <el-image
                        v-else
                        style="width: 120px; height: 120px; background: #f5f5f5; border-radius: 6px;"
                        :src="faceInfo.faceUrl"
                        :preview-src-list="[faceInfo.faceUrl]"
                        fit="contain"></el-image>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <pay-dialog ref="payDialog"></pay-dialog>
        <add-car ref="addCar" @success="addCarSuccess"></add-car>
    </div>
</template>

<script>
import payDialog from '../../components/poppup/pay-dialog.vue';
import util from '../../../../src/utils/util'
import addCar from '../child/addCar.vue'
export default {
    components: { payDialog, addCar },
        data() {
            var checkPhone = (rule, value, callback) => {
                let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error('手机号格式不正确'));
                }
            };
            var isValidateCardNo = (rule, value, callback) => {
                if(this.monthCardInfo.isTempCard == 1){
                    callback()
                }else{
                    if((this.monthCardInfo.type == 2 || this.monthCardInfo.type == 3) && !value){
                        callback(new Error('停车月卡或停车充电月卡绑定用户时，实体卡号不能为空'))
                    }else{
                        callback()
                    }
                }
            };
            return {
                util: util,
                dialogVisible: false,
                form: {
                    phone: '',
                    cardNo: '',
                    bindRfCardNo: '',
                    monthcardId: '',//
                    stationId: '',//
                    stationName: '',
                    needCard: false,
                },
                monthCardInfo: {
                    stationList: []
                },//月卡信息
                carInfo: '',//车辆信息
                userInfo: '',//目标用户信息
                faceInfo: '',//目标用户人脸信息
                canFaceOrCanRfid: '',//当前是否允许RFID或人脸
                isInputCardNo: true,//是否需要输入卡号
                faceUrl: '',
                cardType: 1,
                rules:{
                    phone: [{required: true, message:'请输入手机号', trigger: 'blur'}, { validator: checkPhone, trigger: 'blur' }],
                    cardNo: [{ validator: isValidateCardNo, trigger: 'blur' }],
                    stationId: [{required: true, message:'请选择购买站点', trigger: 'change'}],
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                }
                this.cardType = 1
                this.userInfo = ''
                this.carInfo = ''
                this.faceInfo = ''
                this.faceUrl = ''
            },
            'form.stationId':{
                deep:true,
                handler(val){
                    if(val){
                        this.form.stationName = this.monthCardInfo.stationList.find(res=>{
                            return res.code == val
                        }).name
                    }
                    
                }
            },
            // faceUrl (val) {
            //     if(val){
            //         this.faceAudit()
            //     }
            // }
        },
        methods:{
            submit () {
                
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if(!this.carInfo) {
                            this.$message.error('请上传用户车辆信息')
                            return
                        }
                        if(this.cardType == 2){
                            this.getUserECard()
                            return
                        }
                        let reqData = {
                            monthCardConfigId: this.form.monthcardId,
                            needCard: this.form.needCard,
                            bindCardNo: this.form.cardNo,
                            bindRfCardNo: this.form.bindRfCardNo,
                            stationId: this.form.stationId,
                            targetUserId: this.userInfo.id,
                            userBikeId: this.carInfo.id,
                        }
                        this.createOrder(reqData)
                    }
                })
            },
            async createOrder (reqData) {
                await this.faceAudit()
                this.$Axios._post({
                    url: this.$Config.apiUrl.superPlaceOrder,
                    method: "post",
                    params: {
                        orderType: 1, //订单类型：充电，套餐，实体卡，钱包充值，在线卡余额充值等
                        orderParams: {
                            ...reqData
                        }
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$refs['payDialog'].dialogVisible = true
                        this.$refs['payDialog'].payParams = {
                            orderId: res.result.data.orderId,
                            payAmount: res.result.data.payAmount,
                            orderType: res.result.data.orderType,
                            payWay: this.monthCardInfo.payWay
                        }
                        this.dialogVisible = false
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            addCar () {
                this.$refs['addCar'].dialogVisible = true
                this.$refs['addCar'].targetUserId = this.userInfo.id
            },
            addCarSuccess () {
                this.getUserCarInfo()
            },
            // 获取目标用户信息
            getTargetUserInfo () {
                this.userInfo = ''
                this.carInfo = ''
                this.faceInfo = ''
                let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
                if (!reg.test(this.form.phone)) {
                    return
                }
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUserByPhone,
                    method: "get",
                    params: {
                        phone: this.form.phone
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.userInfo = res.result.data
                        this.getUserFace()
                        this.getUserCarInfo()
                        this.getUserRfcardList()
                    }else{
                        this.userInfo = ''
                        this.faceInfo = ''
                        this.carInfo = ''
                    }
                })
                .catch(err=>{

                }) 
            },
            // 获取用户人脸信息
            getUserFace () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.faceInfo,
                    method: "get",
                    params: {
                        targetUrserId: this.userInfo.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.faceInfo = res.result.data
                    }else{
                        this.faceInfo = ''
                    }
                })
                .catch(err=>{

                }) 
            },
            getMonthcardCanFace () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUserBikeList,
                    method: "get",
                    params: {
                        userId: this.userInfo.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.canFaceOrCanRfid = {
							...res.result.data
						}
                    }else{
                        this.canFaceOrCanRfid = ''
                    }
                })
                .catch(err=>{

                }) 
            },
            // 获取用户车辆信息
            getUserCarInfo () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUserBikeList,
                    method: "get",
                    params: {
                        userId: this.userInfo.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0 && res.result.data && res.result.data.length) {
                        this.carInfo = res.result.data[0]
                    }else{
                        this.carInfo = ''
                    }
                })
                .catch(err=>{

                }) 
            },
            // 获取用户RFID信息
            getUserRfcardList () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getUserRfcardList,
                    method: "post",
                    params: {
                        pageNumber: 1,
						pageSize: 10,
                        userId: this.userInfo.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0 && res.result.data.list && res.result.data.list.length) {
                        this.form.bindRfCardNo = res.result.data.list[0].rfcardNo
                    }else{
                        this.form.bindRfCardNo  = ''
                    }
                })
                .catch(err=>{

                }) 
            },
            // 人脸上传
            faceAudit () {
                return new Promise((resolve,reject)=>{
                    if(!this.faceUrl) {
						resolve()
						return
					}
                    this.$Axios._post({
                        url: this.$Config.apiUrl.faceAdd,
                        method: "post",
                        params: {
                            faceUrl: this.faceUrl,
                            userId: this.userInfo.id
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.getUserFace()
                            resolve()
                        } else {
                            this.$message.error(res.result.msg ? (res.result.msg+'（照片不清晰或未检测到人脸）') : '上传失败')
                        }
                    })
                    .catch(err=>{
                    })
                })
                
            },
            getUserECard () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUserECard,
                    method: "get",
                    params: {
                        userId: this.userInfo.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        let reqData = {
                            monthCardConfigId: this.form.monthcardId,
                            needCard: this.form.needCard,
                            bindCardNo: res.result.data.cardNo,
                            bindRfCardNo: this.form.bindRfCardNo,
                            stationId: this.form.stationId,
                            targetUserId: this.userInfo.id,
                            userBikeId: this.carInfo.id,
                        }
                        this.createOrder(reqData)
                    }
                })
                .catch(err=>{

                }) 
            }
        }
    }
</script>