<template>
    <div>
        <el-dialog
            title="添加车辆"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="车辆类型" prop="type">
                    <el-select v-model="type" @change="getBatterybList">
                        <el-option v-for="item in typeArray"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电瓶规格" prop="batteryVoltage">
                    <el-select v-model="batteryVoltage" >
                        <el-option v-for="item in batteryVoltageArray"
                            :key="item.id"
                            :label="item.batteryVoltage"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="品牌型号" prop="brand">
                    <el-input v-model="form.brand" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="车牌号" prop="carNumber">
                    <el-input v-model="form.carNumber" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="车辆照片" prop="imagePath">
                    <le-upload-file-img ref="uploadImg" label="" :limit="1" v-model="form.imagePath"></le-upload-file-img>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                targetUserId: '',// 添加车辆目标用户id
                typeArray: [],
                type: '',
                batteryVoltageArray: [],
                batteryVoltage: '',
                form: {
					type: '',
					imagePath: '',
					brand: '',
					batteryVoltage: '',
					carNumber: '',//车牌号
                },
                rules: {
                    type: [{required: true, message:'请选择车辆类型', trigger: 'change'}],
					imagePath: [{required: true, message:'请上传车辆图片', trigger: 'change'}],
					brand: [{required: true, message:'请输入车辆品牌', trigger: 'blur'}],
					batteryVoltage: [{required: true, message:'请选择车辆电瓶规格', trigger: 'change'}],
                }
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                    this.$refs['uploadImg'].fileList = []
                }
                this.type = ''
                this.batteryVoltage = ''
            },
            type (val) {
                this.form.type = this.typeArray.find(res=>{
                    return res.id == val
                })
            },
            batteryVoltage (val) {
                this.form.batteryVoltage = this.batteryVoltageArray.find(res=>{
                    return res.id == val
                })
            }
        },
        created () {
            this.getBikeType()
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认增加车辆？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.saveBike,
                                method: "post",
                                params: {
                                    ...this.form,
                                    userId: this.targetUserId
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$emit('success');
                                    this.targetUserId = ''
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
            // 获取车辆类型
			getBikeType () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.bikeList,
                    method: "post",
                    params: {},
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.typeArray = res.result.data
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })	
			},
            // 获取车辆排量类型
			getBatterybList (id) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.batterybList,
                    method: "post",
                    params: {
                        bikeId: id
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.batteryVoltageArray = res.result.data
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
			},
        }
    }
</script>